.qetaQuestionHighlightList {
  display: none !important;
}

.code-container {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;
  overflow-x: auto;
}

pre {
  margin: 0;
  white-space: pre-wrap; /* For wrapping long lines */
  word-wrap: break-word; /* Ensures words wrap properly */
}

code {
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  color: #333;
}
