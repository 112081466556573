/* swagger-custom.css */

:root {
  --swagger-background-color: #f5f5f5;
  --swagger-topbar-background-color: #333;
  --swagger-title-color: #3a3a3a;
  --swagger-text-color: #333;
  --swagger-scheme-container-background-color: #eaeaea;
  --swagger-operation-tag-content-background-color: #fff;
  --swagger-operation-tag-content-border-color: #ddd;
  --swagger-opblock-summary-background-color: #e3e3e3;
  --swagger-opblock-background-color: #fff;
  --swagger-response-background-color: #fff;
  --swagger-response-header-color: #333;
  --swagger-response-code-color: #333;
  --swagger-param-background-color: #f5f5f5;
  --swagger-param-border-color: #ddd;
  --swagger-button-background-color: #007bff;
  --swagger-button-color: #fff;
  --swagger-button-hover-background-color: #0056b3;
  --swagger-opblock-tag-background-color: #fafafa;
  --swagger-opblock-tag-border-color: #ccc;
  --swagger-opblock-tag-color: #333;
  --swagger-param-name-color: #333;
}

.swagger-ui {
  background-color: var(--swagger-background-color) !important;
  color: var(--swagger-text-color) !important;
}

.swagger-ui .topbar {
  background-color: var(--swagger-topbar-background-color) !important;
}

.swagger-ui .info .title {
  color: var(--swagger-title-color) !important;
}

.swagger-ui .scheme-container {
  background-color: var(--swagger-scheme-container-background-color) !important;
}

.swagger-ui .operation-tag-content {
  background-color: var(
    --swagger-operation-tag-content-background-color
  ) !important;
  border: 1px solid var(--swagger-operation-tag-content-border-color) !important;
}

.swagger-ui .opblock-summary {
  background-color: var(--swagger-opblock-summary-background-color) !important;
}

.swagger-ui .opblock {
  background-color: var(--swagger-opblock-background-color) !important;
}

.swagger-ui .responses-inner .response {
  background-color: var(--swagger-response-background-color) !important;
}

.swagger-ui .responses-inner .response .response-col_description {
  color: var(--swagger-response-header-color) !important;
}

.swagger-ui .responses-inner .response .response-col_status {
  color: var(--swagger-response-code-color) !important;
}

.swagger-ui .parameters-col_description {
  color: var(--swagger-param-name-color) !important;
}

.swagger-ui .parameters-col_name {
  color: var(--swagger-param-name-color) !important;
  div {
    color: var(--swagger-param-name-color) !important;
  }
}

.swagger-ui .btn {
  background-color: var(--swagger-button-background-color) !important;
  color: var(--swagger-button-color) !important;
}

.swagger-ui .btn:hover {
  background-color: var(--swagger-button-hover-background-color) !important;
}

.swagger-ui .opblock-tag {
  background-color: var(--swagger-opblock-tag-background-color) !important;
  border: 1px solid var(--swagger-opblock-tag-border-color) !important;
  color: var(--swagger-opblock-tag-color) !important;
  span,
  p {
    color: var(--swagger-opblock-tag-color) !important;
  }
}

.swagger-ui .response-col_status,
.swagger-ui .response-col_description {
  color: var(--swagger-param-name-color) !important;
}

.swagger-ui .opblock .opblock-summary-path,
.opblock-summary-description {
  color: var(--swagger-param-name-color) !important;
  span {
    color: var(--swagger-param-name-color) !important;
  }
}

.swagger-ui .model,
.swagger-ui .model-title,
.swagger-ui section.models h4 span {
  color: var(--swagger-param-name-color) !important;
}

.swagger-ui .info li,
.swagger-ui .info p,
.swagger-ui .info table,
.swagger-ui .info h1,
.swagger-ui .info .base-url {
  color: var(--swagger-param-name-color) !important;
}
